import React from 'react';
import Header from '../components/Header/Header';
import './confidentiality.css';
import Footer from '../components/Footer/Footer';

const Confidentiality = () => {
    return (
        <div>
            <Header/>
            <div className='policy'>
                <h1>
                POLITIQUES DE CONFIDENTIALITES
                </h1>
                <br/>
                <h3>Dernière mise à jour : 28/09/23</h3>
                <br/>
                <strong>Cette politique de confidentialité régit la manière dont GabTrotter collecte, utilise et partage les informations recueillies auprès des utilisateurs de l'application mobile STEAM EQUITY (ci-après désignée "l'Application"). Votre utilisation de l'Application signifie que vous acceptez les termes de cette politique de confidentialité.</strong>
                    <br/>
                    <br/>
                <div className='part'>
                    <div className='part-item'>
                        <h3>## Informations collectées</h3>
                        <p>L'Application collecte certaines informations personnelle identifiable telle que nom et le pays de résidence</p>
                    </div>
                    
                    <div className='part-item'>
                    <h3>## Données d'utilisation</h3>
                    <p>Lorsque vous utilisez l'Application, des données d'utilisation anonymes peuvent être collectées automatiquement, telles que des informations sur la manière dont vous interagissez avec l'Application et les fonctionnalités que vous utilisez. Ces données sont collectées de manière à améliorer les performances et les fonctionnalités de l'Application, mais elles ne permettent pas de vous identifier personnellement.</p>
                    </div>

                    <div className='part-item'>
                    <h3>## Cookies</h3>
                    <p>L'Application n'utilise pas de cookies ni de technologies similaires pour collecter des informations.</p>
                    </div>

                    <div className='part-item'>
                    <h3>## Partage d'informations</h3>
                    <p>Nous ne partageons pas les informations collectées auprès des utilisateurs de l'Application avec des tiers, sauf dans les cas suivants :

- **Exigences légales** : Nous pouvons divulguer des informations si la loi l'exige, notamment pour répondre à des assignations à comparaître, des ordres de tribunal ou d'autres exigences légales</p>
                    </div>

                    <div className='part-item'>
                    <h3>## Sécurité</h3>
                    <p>Nous nous engageons à protéger la sécurité de vos informations. Cependant, veuillez noter qu'aucune transmission de données sur Internet ou de stockage électronique n'est totalement sécurisée, et nous ne pouvons garantir la sécurité absolue des informations.</p>
                    </div>

                    <div className='part-item'>
                    <h3>## Modifications de la politique de confidentialité</h3>
                    <p>Nous nous réservons le droit de mettre à jour cette politique de confidentialité à tout moment. Toute modification de cette politique de confidentialité sera publiée sur cette page.</p>
                    </div>

                    <div className='part-item'>
                    <h2>##Contactez-nous</h2>
                    <p>Si vous avez des questions ou des préoccupations concernant cette politique de confidentialité, veuillez nous contacter à l'adresse suivante : <strong>contact@gabtrotter.com</strong></p>
                    </div>

                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default Confidentiality;
