import {BrowserRouter, Routes ,Route } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import Confidentiality from './pages/Confidentiality';
import NoPage from './pages/NoPage';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
          <Routes>
            <Route index element={<Home/>}/>
            <Route index element={<Confidentiality/>} path='/confidentiality'/>
            <Route index element={<NoPage/>} path='*'/>
          </Routes>
      </BrowserRouter>
      
      
    </div>
  );
}

export default App;
