import React from 'react';
import GallerySponsors from './GallerySponsors/Gallery';
import './partners-list.css';

const PartnersList = () => {
    return (
        <div id='partners-list'>
            <GallerySponsors imageList={"images_sponsors"}/>
        </div>
    );
}

export default PartnersList;
