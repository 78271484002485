import React from 'react';
import './footer.css'; // Ajoutez un fichier CSS pour des styles personnalisés
import logo from './logo-blanc.png'; // Remplacez le chemin par votre logo
import { HashLink  as Link } from 'react-router-hash-link';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">
                <div className="footer-content">
                    <div className="footer-logo">
                        <img src={logo} alt="Logo" />
                    </div>
                    <div className="footer-links">
                        <ul>
                        <li><Link to="/">Accueil</Link></li>
                        <li><Link to="/#projects">Projets</Link></li>
                        <li><Link to="/#gallery">Galerie</Link></li>
                        <li><Link to="/#partners">Partenaires</Link></li>
                        <li><Link to="/confidentiality">Confidentialité</Link></li>
                        <li><Link to="/contact-form">Contact</Link></li>
                        </ul>
                    </div>
                    <div className="footer-social">
                        <ul>
                            <li><a href="https://www.facebook.com/gabtrotterNGO" target='_blank'><i className="bi bi-facebook"></i></a></li>
                            <li><a href="https://www.linkedin.com/company/gabtrotter/" target='_blank'><i className="bi bi-linkedin"></i></a></li>
                            <li><a href="https://wa.me/message/2X4GG6IFAU33O1" target='_blank'><i className="bi bi-whatsapp"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
