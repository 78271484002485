import React from 'react';
import ProjectItem from './Project-item/Project-item';
import './projects.css';
import ProjectList from './Project-list/Project-list';


const Projects = () => {
    return (
        <div id="projects">
            <h1>PROJETS</h1>
            <p>GABTROTTER est une organisation à but non lucratif créée en 2018 dans le but de promouvoir l'utilisation responsable des technologies et des médias dans l'éducation. </p>
        <br/>
            <p>Notre action vise à contribuer à la réduction des Violences Basées sur le Genre (VBG) auprès de 1 980 filles et femmes âgées de 15 à 65 ans dans les villes de Libreville, Port-Gentil et Lambaréné d'ici juillet 2025. Pour atteindre cet objectif, nous avons défini deux axes stratégiques : la prévention des VBG et la promotion des Droits à Santé Sexuelle et Reproductive (DSSR) auprès des jeunes, ainsi que le renforcement des compétences numériques des femmes.</p>
            <ProjectList/>
        </div>
    );
}

export default Projects;
