import React from 'react';
import PartnersList from './Partners-list/Partners-list';
import './partners.css';
import leftImage from './assets/partners-left.png';
const Partners = () => {
    return (
        <div id='partners'>
            <div className='left'>
                <img src={leftImage} width={'100%'}/>
                <h2>PARTENAIRES</h2>
                <p>Le projet PERMIS DE CONDUIRE NUMÉRIQUE est un partenariat entre plusieurs acteurs clés qui se réunissent pour promouvoir l'insertion professionnelle des jeunes femmes au Gabon. Il est financé par le fonds FEF OSC 2024 de l'ambassade de France au Gabon et à Sao-Tomé et Principe .</p>
            </div>
            <div className='right'>
                <PartnersList />
            </div>
        </div>
    );
}

export default Partners;
