import React from 'react';
import './rapport.css'
import RapportList from './Rapport-list/Rapport-list';

const Rapport = () => {
    return (
        <div id='rapport'>
            <h1>NOS CHIFFRES</h1>
            <p>Autonomiser les jeunes et les femmes par la promotion de l'utilisation responsable des technologies et des médias.</p>
            
            <button className='button-download'><a href='https://drive.google.com/file/d/1VcOpwzrybv_9KEhEKrUy0B3lzoblzbAW/view?usp=drivesdk' target='_
            blank'>Telecharger la presentation</a></button>
            <RapportList/>
        </div>
    );
}

export default Rapport;
