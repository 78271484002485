import React from 'react';
import './gallery.css';
import Image from './Image/Image';
import AFG from './assets/AFG.jpg';
import AMFG from './assets/AMFG.jpg';
import AURORE from './assets/AURORE.jpg';
import DHF from './assets/DHF.jpg';
import EGALITE from './assets/EGALITE.png';
import IREX from './assets/IREX.jpg';
import LAC from './assets/LAC.jpg';
import LEX from './assets/LEX.png';
import LINK from './assets/LINK.png';
import MRG from './assets/MRG.jpg';
import MWF from './assets/MWF.jpg';
import POLY from './assets/POLY.png';
import QG_JEUNES from './assets/QG JEUNES.png';
import USE from './assets/USE.png';
import BISTEPHE from './assets/BISTEPHE.jpg';
import ADA from './assets/ADA.jpg';
import AGCE from './assets/AGCE.jpg';
import COEUR from './assets/Coeur_de_sante.jpg';
import EFM from './assets/EFM.png';
import PME from './assets/espacePME.jpg';
import MairiePOG from './assets/MairiePOG.jpg';
import MairieLBV from './assets/MairieLBV.jpg';
import PISCCA from './assets/PISCCA - Logo - Violet .png';
import PNPE from './assets/logo-lgn.png';
import FOSCOM from './assets/FOSCOM.jpg';


const GallerySponsors = ({imageList}) => {



    const images_sponsors = [
        {
            image: AMFG,
        },
        {
            image:EGALITE
        },
        {
            image:MairieLBV
        },
        {
            image:MairiePOG
        },
        {
            image:PNPE
        },
        {
            image: PME,
        },
        {
            image: IREX,
        },
        {
            image: USE,
        },
        {
            image: LAC,
        },
        {
            image: QG_JEUNES,
        },
        {
            image: DHF,
        },
        {
            image: AURORE,
        },
        {
            image: COEUR,
        },
        {
            image: ADA,
        },
        {
            image: FOSCOM,
        },
        {
            image: EFM,
        },
        {
            image: LEX,
        },
        {
            image:BISTEPHE,
        },
        {
            image: LINK,
        },
        {
            image: AFG,
        },
        {
            image: POLY,
        },
        {
            image: MRG,
        },

        
    ]
    return (

        <div id='gallery'>
            {imageList == "images_sponsors" && (
                images_sponsors.map((image, index) => (
                    <Image key={index} image={image.image} />
                ))
            )}

    </div>
    );
}

export default GallerySponsors;
