import React, { useState } from 'react';
import {HashLink as Link} from 'react-router-hash-link';
import logo from "./assets/logo-blanc.png"
import './header.css';

const Header = () => {

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    }
    return (
<header className="main-header" id='main-header'>
        <div className="nav">
            <div className="logo">
                <img src={logo} alt=""/>
            </div>
            <div className="menu mobile-btn" onClick={toggleMobileMenu}>
                <i className={!mobileMenuOpen ? "bi bi-list" : "bi bi-x"} id="btn-menu"></i>
            </div>
            <div className="menu desktop">
                <nav>
                    <ul>
                        <li><Link to="/">Accueil</Link></li>
                        <li><Link to="/#projects">Projets</Link></li>
                        <li><Link to="/#gallery">Galerie</Link></li>
                        <li><Link to="/#partners">Partenaires</Link></li>
                        <li><Link to="/confidentiality">Politiques de confidentialité</Link></li>
                        <li><Link to="/#contact-form">Contact</Link></li>
                        
                    </ul>
                </nav>
                <hr/>
            </div>
        </div>
        {mobileMenuOpen &&
        <div className="menu mobile" style ={{display:"block"}}  >
            <nav>
                <ul>
                    <li><Link to="/">Accueil</Link></li>
                    <li><Link to="/#projects">Projets</Link></li>
                    <li><Link to="/#gallery">Galerie</Link></li>
                    <li><Link to="/#partners">Partenaires</Link></li>
                    <li><Link to="/confidentiality">Politiques de confidentialité</Link></li>
                    <li><Link to="/#contact-form">Contact</Link></li>
                </ul>
            </nav>
        </div>
}
        <div className="content">
            <h1>TROTTONS POUR L'AUTONOMISATION DES FEMMES</h1>
            <div className="social">
                <ul>
                    <li><a href='https://www.facebook.com/gabtrotterNGO' target='_blank'><i className="bi bi-facebook"></i></a></li>
                    <li><a href='https://www.linkedin.com/company/gabtrotter/' target='_blank'><i className="bi bi-linkedin"></i></a></li>
                    <li><a href='https://wa.me/message/2X4GG6IFAU33O1' target='_blank'><i className="bi bi-whatsapp"></i></a></li>
                </ul>
            </div>
        </div>
    </header>
    );
}
export default Header;
